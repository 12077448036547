import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton, Typography, Alert, Table, Button } from 'antd';
import axios from 'axios';
import styled from 'styled-components';

const { Title } = Typography;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
`;

const ExportWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const columns = [
  {
    title: 'Referral Date',
    dataIndex: 'referral_date',
    key: 'referred_date',
    render: (text: string | null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Institution Referred',
    dataIndex: 'institution_referred',
    key: 'institution_referred',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Contact Person',
    dataIndex: 'contact_person',
    key: 'contact_person',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Organisation',
    dataIndex: 'organisation',
    key: 'organisation',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Place',
    dataIndex: 'place',
    key: 'place',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Household Head',
    dataIndex: 'household_head',
    key: 'household_head',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Services Requested',
    dataIndex: 'services_requested',
    key: 'services_requested',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    key: 'reason',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Services Already Provided',
    dataIndex: 'services_already_provided',
    key: 'services_already_provided',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Consent',
    dataIndex: 'consent',
    key: 'consent',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Person Referring',
    dataIndex: 'person_referring',
    key: 'person_referring',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Receiver Name',
    dataIndex: 'receiver_name',
    key: 'receiver_name',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Services Provided',
    dataIndex: 'services_provided',
    key: 'services_provided',
    render: (text = null) => text ? text : 'Not Applicable',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    render: (text = null) => text ? text : 'Not Applicable',
  },
];

const cleanData = (data: any[]) => {
  return data.map((record) => {
    const cleanedRecord = { ...record };


    Object.keys(cleanedRecord).forEach((key) => {
      if (cleanedRecord[key] === null || cleanedRecord[key] === undefined) {
        cleanedRecord[key] = 'Not Applicable';
      }

      if (typeof cleanedRecord[key] === 'string') {
        cleanedRecord[key] = cleanedRecord[key].replace(/[\[\]"]/g, '');
      }
    });

    return cleanedRecord;
  });
};

export const VcaReferrals: React.FC = () => {
  const location = useLocation();
  const vcaId = location.state?.vca.unique_id;

  const [isLoading, setLoading] = useState(false);
  const [serviceRecords, setServiceRecords] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (vcaId) {
      setLoading(true);
      axios
        .get(`https://server.achieve-dqa.bluecodeltd.com/child/vca-referrals/${vcaId}`)
        .then((response) => {
          const data = cleanData(response.data.data);
          setServiceRecords(data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [vcaId]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Skeleton active />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Alert
          message="We encountered an error fetching service records. Refresh the page to see if the issue persists."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <Wrapper>
      <Title>Vca Referrals</Title>
      <Table
        scroll={{ x: 200 }}
        columns={columns}
        dataSource={serviceRecords}
        pagination={false}
        rowKey="referral_date"
      />
    </Wrapper>
  );
};
