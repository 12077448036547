import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Skeleton, Typography, Alert, List, Button, Row, Col, Tag, Divider } from 'antd';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import styled from 'styled-components';
import axios from 'axios';

const SectionTitle = styled(Typography.Title)`
  font-size: 18px;
  color: #004080;
  margin-bottom: 10px;
`;

const InfoLabel = styled(Typography.Text)`
  font-weight: bold;
  color: #006baf;
`;

const InfoValue = styled(Typography.Text)`
  margin-right: 20px;
  color: #333;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled(Typography.Title)`
  font-size: 22px;
  color: #004080;
`;

const Subtitle = styled(Typography.Text)`
  font-size: 16px;
  color: #004080;
`;

const ListItemWrapper = styled(Row)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
`;

interface Household {
  first_name: string;
  last_name: string;
  birthdate: string;
  gender: string;
  province: string;
  district: string;
  cwac: string;
  date_created: string;
  disability: string;
  relationship: string;
  other_relationship: string | null;
  is_index: string | number;
  unique_id: string;
  household_id: string;
  ward: string;
  year: number;
}

export const HouseholdMembersInfo: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const household: Household | undefined = location.state?.household;

  const [isLoading, setLoading] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (household && household.household_id) {
      setLoading(true);
      axios
        .get(`https://server.achieve-dqa.bluecodeltd.com/household/members/${household.household_id}`)
        .then((response) => {
          const sortedMembers = response.data.data.sort((a: { is_index: any }, b: { is_index: any }) => {
            if (a.is_index === b.is_index) {
              return 0;
            }
            return a.is_index ? -1 : 1; // Primary VCA appears first
          });
          setMembers(sortedMembers);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [household]);

  const handleViewProfile = (unique_id: string) => {
    const member = members.find((m) => m.unique_id === unique_id);

    if (member) {
      localStorage.setItem('selectedVCA', JSON.stringify(member)); // Save to localStorage
      navigate(`/profile/vca-profile/${encodeURIComponent(unique_id)}`, { state: { vca: member } });
    } else {
      console.error('Member not found with the given unique_id:', unique_id);
    }
  };


  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton active />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Alert
          message="We encountered an error fetching households. Refresh the page to see if the issue persists."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <Wrapper>
      {household && (
        <>
          <Title>Family Members</Title>
          <Subtitle>This section lists all family members in the household.</Subtitle>
          <br />
          <br />
          <BaseCard>
            <Divider />
            {members.map((member) => (
              <div key={member.unique_id}>
                <ListItemWrapper gutter={[16, 16]}>
                  <Col span={8}>
                    <Col span={24}>
                      <InfoValue>ID: {member.unique_id}</InfoValue>
                    </Col>
                    <Col span={24}>
                      <InfoValue>
                        {member.first_name} {member.last_name}
                      </InfoValue>
                    </Col>
                  </Col>

                  <Col span={8}>
                    <Col span={24}>
                      <InfoValue>
                        Child type: &nbsp;
                        <Tag color={member.is_index === '1' ? 'green' : 'red'}>
                          {member.is_index === '1' ? 'Index VCA' : 'Family Member'}
                        </Tag>
                      </InfoValue>
                    </Col>
                    <Col span={24}>
                      <InfoValue>
                        Gender: &nbsp;
                        <Tag color={member.gender.toLowerCase() === 'male' ? 'blue' : 'purple'}>
                          {member.gender}
                        </Tag>
                      </InfoValue>
                    </Col>
                    <Col span={24}>
                      <InfoValue>Disability: {member.disability}</InfoValue>
                    </Col>
                  </Col>

                  <Col span={8}>
                    <Col span={24}>
                      <InfoValue>Relationship: {member.relationship}</InfoValue>
                    </Col>
                    <Col span={24}>
                      <InfoValue>Birthdate: {member.birthdate}</InfoValue>
                    </Col>
                    {member.other_relationship && (
                      <Col span={24}>
                        <InfoValue>Other Relationship: {member.other_relationship}</InfoValue>
                      </Col>
                    )}
                  </Col>

                  <Col span={24}>
                    <Button type="primary" onClick={() => handleViewProfile(member.unique_id)}>
                      View Profile
                    </Button>
                  </Col>
                </ListItemWrapper>
              </div>
            ))}
          </BaseCard>
        </>
      )}
    </Wrapper>
  );
};