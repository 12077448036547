import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Skeleton, Typography, Divider, Badge, Tag } from 'antd'; // <- Note: we use Badge from antd
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { notificationController } from '@app/controllers/notificationController';
import styled from 'styled-components';
import axios from 'axios';

const SectionTitle = styled(Typography.Title)`
  font-size: 18px;
  color: #004080;
  margin-bottom: 10px;
`;

const InfoLabel = styled(Typography.Text)`
  font-weight: bold;
  color: #006baf;
`;

const InfoValue = styled(Typography.Text)`
  display: block;
  margin-bottom: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled(Typography.Title)`
  font-size: 22px;
  color: #004080;
`;

const Subtitle = styled(Typography.Text)`
  font-size: 16px;
  color: #004080;
`;

interface Vcas {
  unique_id: string;
  first_name: string;
  last_name: string;
  gender: string;
  province: string;
  district: string;
  household_id: string;
  cwac: string;
  month: string;
  is_index: string;
  date_created: string;
  last_interacted_with: string;
  year: number;
  cwac_name: string;
  cwac_code: string;
  village: string;
  ward: string;
  cwac_member_name: string;
  risk_level?: string;
}

interface PersonalInfoProps {
  profileData?: Vcas;
}

export const VcaPersonalInfo: React.FC<PersonalInfoProps> = ({ profileData }) => {

  const location = useLocation();
  const vca = location.state?.vca;

  useEffect(() => {
  }, [vca]);

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Use the Vca data directly for form initial values
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();

  const [identificationData, setIdentificationData] = useState<any[]>([]);

  /**
   * A helper function to remove double quotes and square brackets from a string.
   * If your data sometimes contains arrays, you may want to handle them differently,
   * e.g. parse JSON arrays and join them. This approach simply strips literal characters.
   */
  const cleanValue = (value: any) => {
    if (!value) return value; // return as is if null or undefined

    let result = value;
    // If it's an array, optionally handle it differently:
    if (Array.isArray(result)) {
      // For example, you could join the array:
      // result = result.join(', '); 
      // Or you could convert to a string:
      result = JSON.stringify(result);
    }

    if (typeof result === 'string') {
      // Remove all quotes
      result = result.replace(/"/g, '');
      // Remove [ and ]
      result = result.replace(/\[/g, '').replace(/\]/g, '');
    }

    return result;
  };

  /**
   * Fetch identification data for the current VCA.
   */
  const fetchData = useCallback(async () => {
    if (!vca?.unique_id) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `https://server.achieve-dqa.bluecodeltd.com/child/identification/${vca.unique_id}`
      );

      setIdentificationData(response.data.data);
      localStorage.setItem('vcas', JSON.stringify(response.data.data));

    } catch (error) {
      console.error('Error fetching VCAs data:', error);
    } finally {
      setLoading(false);
    }
  }, [vca?.unique_id]);

  /**
   * Call fetchData when the component mounts or when vca.unique_id changes.
   */
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onFinish = useCallback(
    (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setFieldsChanged(false);
        notificationController.success({ message: t('common.success') });
      }, 600);
    },
    [t],
  );

  if (isLoading || !vca) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton active />
      </div>
    );
  }

  /**
   * A helper function to display label/value pairs in a column.
   */
  const renderCol = (label: string, value: any, span: number = 6) => (
    <BaseCol xs={24} md={span}>
      <InfoLabel>{label}</InfoLabel>
      <InfoValue>
        {value !== null && value !== undefined ? value : 'Not Applicable'}
      </InfoValue>
    </BaseCol>
  );

  /**
   * A helper to display a Tag for the risk level.
   *   0 => grey => Unknown
   *   1 => green => Low
   *   2 => orange => Medium
   *   3 => red => High
   */
  const getRiskLevelTag = (riskLevel?: string) => {
    switch (riskLevel) {
      case '0':
        return <Tag color="grey">Unknown</Tag>;
      case '1':
        return <Tag color="green">Low</Tag>;
      case '2':
        return <Tag color="orange">Medium</Tag>;
      case '3':
        return <Tag color="red">High</Tag>;
      default:
        return <Tag color="default">Not Provided</Tag>;
    }
  };

  const idData = identificationData.length > 0 ? identificationData[0] : {};

  return (
    <Wrapper>
      <BaseCard>

        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={{
            firstName: vca.first_name,
            lastName: vca.last_name,
          }}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
        >
          <Title>{`${vca.first_name} ${vca.last_name}`}</Title>
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <Divider />
            <BaseCol span={24}>
              <SectionTitle level={5}>
                {t('VCA Personal Information')}
              </SectionTitle>
            </BaseCol>
            {renderCol('Household ID', cleanValue(vca.household_id), 8)}
            {renderCol('Unique ID', cleanValue(vca.unique_id), 8)}
            {renderCol('Date Created', cleanValue(vca.date_created), 8)}
            {renderCol('Month', vca.month, 8)}
            {renderCol('Year', vca.year, 8)}
            {renderCol(
              'Child Type',
              vca.is_index === '0' ? 'Family Member' : 'Index VCA',
              8
            )}
            {renderCol('Risk Level', getRiskLevelTag(cleanValue(vca.risk_level)), 8)}

            <Divider />

            {renderCol('Province', vca.province, 8)}
            {renderCol('District', vca.district, 8)}
            {renderCol('CWAC Name', vca.cwac_name, 8)}
            {renderCol('CWAC Code', vca.cwac_code, 8)}
            {renderCol('Village', vca.village, 8)}

            <Divider />

            <BaseCol span={24}>
              <SectionTitle level={5}>{t('CWAC Member Information')}</SectionTitle>
            </BaseCol>

            {renderCol('CWAC Member Name', vca.cwac_member_name, 8)}
            {renderCol('Last Interacted With', vca.last_interacted_with, 8)}

            <Divider />

            <BaseCol span={24}>
              <SectionTitle level={5}>
                {t('VCA Identification Information')}
              </SectionTitle>
            </BaseCol>
            {renderCol('Adhering', cleanValue(idData.adhering), 8)}
            {renderCol('ART', cleanValue(idData.art), 8)}
            {renderCol('Birth Certificate', cleanValue(idData.birth_certificate), 8)}
            {renderCol('Child Concerns', cleanValue(idData.child_concerns), 8)}
            {renderCol('Child Eat', cleanValue(idData.child_eat), 8)}
            {renderCol('Child Eat Daily', cleanValue(idData.child_eat_daily), 8)}
            <Divider />
            {renderCol('Child Enrolled', cleanValue(idData.child_enrolled), 8)}
            {renderCol('Child Enrolled Yes', cleanValue(idData.child_enrolled_yes), 8)}
            {renderCol('Child Ill', cleanValue(idData.child_ill), 8)}
            {renderCol('Child Immunization', cleanValue(idData.child_immunization), 8)}
            {renderCol('Child Missed', cleanValue(idData.child_missed), 8)}
            {renderCol('Child Not Enrolled', cleanValue(idData.child_not_enrolled), 8)}
            {renderCol('Child Pregnant', cleanValue(idData.child_pregnant), 8)}
            {renderCol('Child Pregnant Yes', cleanValue(idData.child_pregnant_yes), 8)}
            {renderCol('Child Tested', cleanValue(idData.child_tested), 8)}
            <Divider />
            {renderCol('Comments Education', cleanValue(idData.comments_education), 8)}
            {renderCol('Comments Health', cleanValue(idData.comments_health), 8)}
            {renderCol('Comments HIV', cleanValue(idData.comments_hiv), 8)}
            {renderCol('Comments Household Income', cleanValue(idData.comments_household_income), 8)}
            <Divider />
            {renderCol('Date Created', cleanValue(idData.date_created), 8)}
            {renderCol('Date First Visit', cleanValue(idData.date_first_visit), 8)}
            {renderCol('Date Second Visit', cleanValue(idData.date_second_visit), 8)}
            {renderCol('District', cleanValue(idData.district), 8)}
            {renderCol('Family Benefit', cleanValue(idData.family_benefit), 8)}
            {renderCol('Family Meals', cleanValue(idData.family_meals), 8)}
            <Divider />
            {renderCol('Family Members ART', cleanValue(idData.family_members_art), 8)}
            {renderCol('Family Members HIV', cleanValue(idData.family_members_hiv), 8)}
            {renderCol('HIV Status', cleanValue(idData.hiv_status), 8)}
            {renderCol('Household ID', cleanValue(idData.household_id), 8)}
            {renderCol('Income Sufficient', cleanValue(idData.income_sufficient), 8)}
            {renderCol('Issues Reported', cleanValue(idData.issues_reported), 8)}
            <Divider />
            {renderCol('Known Status', cleanValue(idData.known_status), 8)}
            {renderCol('Last Interacted With', cleanValue(idData.last_interacted_with), 8)}
            {renderCol('Month', cleanValue(idData.month), 8)}
            {renderCol('Occupation Head', cleanValue(idData.occupation_head), 8)}
            {renderCol('Other', cleanValue(idData.other), 8)}
            {renderCol('Other Child Eats', cleanValue(idData.other_child_eats), 8)}
            <Divider />
            {renderCol('Other Child Not Enrolled', cleanValue(idData.other_child_not_enrolled), 8)}
            {renderCol('Other Family Benefit', cleanValue(idData.other_family_benefit), 8)}
            {renderCol('Other Not Reported', cleanValue(idData.other_not_reported), 8)}
            {renderCol('Other Unknown Status', cleanValue(idData.other_unknown_status), 8)}
            {renderCol('Overall Concerns', cleanValue(idData.overall_concerns), 8)}
            {renderCol('Past Experience', cleanValue(idData.past_experience), 8)}
            <Divider />
            {renderCol('Provider ID', cleanValue(idData.provider_id), 8)}
            {renderCol('Province', cleanValue(idData.province), 8)}
            {renderCol('Reason Missed', cleanValue(idData.reason_missed), 8)}
            {renderCol('Source of Income', cleanValue(idData.source_of_income), 8)}
            {renderCol('Stable Source Income', cleanValue(idData.stable_source_income), 8)}
            <Divider />
            {renderCol('Street Time', cleanValue(idData.street_time), 8)}
            {renderCol('Traditional Interventions', cleanValue(idData.traditional_interventions), 8)}
            {renderCol('Under 5 Card', cleanValue(idData.under_5_card), 8)}
            <Divider />
            {renderCol('Unknown Status', cleanValue(idData.unknown_status), 8)}
            {renderCol('Viral Load', cleanValue(idData.viral_load), 8)}
            {renderCol('Vulnerability Status', cleanValue(idData.vulnerability_status), 8)}
            {renderCol('Why Not Reported', cleanValue(idData.why_not_reported), 8)}
            <Divider />
          </BaseRow>
        </BaseButtonsForm>
      </BaseCard>
    </Wrapper>
  );
};
