import React, { useEffect, useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { Skeleton, Tabs } from 'antd';
import { VcaPersonalInfo } from './profileCard/ProfileInfo/VcaPersonalInfo';
import VcaFlaggedRecordForm from './profileCard/ProfileInfo/VcaFlaggedRecordForm';
import axios from 'axios';
import { VcaCasePlansAndServices } from './profileCard/ProfileInfo/VcaCasePlansAndServices';
import { VcaReferrals } from './profileCard/ProfileInfo/VcaReferrals';

const VcaProfileLayout: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const { unique_id } = useParams(); // Extract unique_id from URL

  const { isTablet } = useResponsive();
  const [vca, setVca] = useState(location.state?.vca || null);
  const [loading, setLoading] = useState(false);

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/profile');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/profile');

  useEffect(() => {
    if (!vca && unique_id) {
      setLoading(true);
      axios
        .get(`https://server.achieve-dqa.bluecodeltd.com/household/members/${unique_id}`)
        .then((response) => {
          setVca(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching VCA data:', error);
          setLoading(false);
        });
    }
  }, [unique_id, vca]);

  useEffect(() => {
    isTablet && location.pathname === '/apps' && navigate('vcas-register');
  }, [isTablet, location.pathname, navigate]);

  if (loading || !vca) {
    return <Skeleton active />;
  }

  // Define tab items
  const tabItems = [
    {
      key: 'vca-overview',
      label: t('VCA Profile'),
      children: (
        <VcaPersonalInfo profileData={vca} />
      ),
    },
    {
      key: 'vca-case-plans',
      label: t('VCA Case Plans and Services'),
      children: (
        <VcaCasePlansAndServices />
      ),
    },
    {
      key: 'referrals',
      label: t('Referrals'),
      children: <VcaReferrals />
    },
    {
      key: 'flag-record-form',
      label: t('Flag Record Form'),
      children: <VcaFlaggedRecordForm />,
    },
  ];

  return (
    <Wrapper>
      <PageTitle>{t('Profile Overview')}</PageTitle>
      {(isTabletOrHigher || !isTitleShown) && (
        <>

          <BaseButton
            icon={<LeftOutlined />}
            type="dashed"
            onClick={() => navigate(-1)}
            style={{
              borderRadius: '90px', // Rounded corners
              padding: '0.5rem 1rem', // Adjust padding if needed
            }}
          >
            {t('Go Previous')}
          </BaseButton>
          <br />
        </>
      )}
      <BaseRow gutter={[30, 30]}>
        <BaseCol span={24}>
          <ProfileCard>
            <Tabs defaultActiveKey="vca-overview" items={tabItems} />
          </ProfileCard>
        </BaseCol>
      </BaseRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

const ProfileCard = styled(BaseCard)`
  height: unset;
`;

export default VcaProfileLayout;
