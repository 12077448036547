import React from 'react';
import {
  UserOutlined,
  HomeTwoTone,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const PMPsidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Overview',
    key: 'mis-dashboard',
    url: '/community-case-management',
    icon: <HomeTwoTone />,
  },
  {
    title: 'common.households-register',
    key: 'households-register',
    url: '/apps/households-register',
    icon: <UserOutlined />,
  },
  {
    title: 'common.vcas-register',
    key: 'vcas-register',
    url: '/apps/vcas-register',
    icon: <UserOutlined />,
  },
  // {
  //   title: 'common.households-members-register',
  //   key: 'households-members-register',
  //   url: '/apps/households-members-register',
  //   icon: <UserOutlined />,
  // },
  {
    title: 'Flagged Records',
    key: 'flagged-records',
    url: '/flagged-records',
    icon: <ExclamationCircleOutlined />,
  }
];
