import React, { useEffect, useState } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography, Alert, Table, Button, Modal, Tag } from 'antd';
import axios from 'axios';
import styled from 'styled-components';
import { Pagination } from 'api/table.api';

const { Title } = Typography;

interface CasePlan {
  household_id: string;
  case_plan_id: string;
  case_plan_date: string | null;
  case_plan_status: Status | null;
}

interface Service {
  household_id: string;
  case_plan_id: string; 
  type: string;
  status: Status | null;
  vulnerability: string;
  goal: string;
  services: string;
  service_referred: string;
  institution: string;
  due_date: string | null;
}

type Status = 'open' | 'in progress' | 'delayed';

const statusColorMap: Record<Status, string> = {
  open: 'green',
  'in progress': 'orange',
  delayed: 'red',
};

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
`;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

const cleanData = (data: any[]) => {
  return data.map((record) => {
    const cleanedRecord = { ...record };
    Object.keys(cleanedRecord).forEach((key) => {
      if (cleanedRecord[key] === null || cleanedRecord[key] === undefined) {
        cleanedRecord[key] = 'Not Applicable';
      }
      if (typeof cleanedRecord[key] === 'string') {
        cleanedRecord[key] = cleanedRecord[key].replace(/[\[\]"]/g, '');
      }
    });
    return cleanedRecord;
  });
};

interface TableDataState {
  data: CasePlan[];
  pagination: Pagination;
  loading: boolean;
}

export const HouseholdCasePlansAndServices: React.FC = () => {

  const location = useLocation();
  const householdId = location.state?.household.household_id;
  const [casePlans, setCasePlans] = useState<CasePlan[]>([]);
  const [serviceRecords, setServiceRecords] = useState<Service[]>([]);
  const [filteredCasePlans, setFilteredCasePlans] = useState<CasePlan[]>([]);
  const [filteredServices, setFilteredServices] = useState<Service[]>([]);
  const [tableData, setTableData] = useState<TableDataState>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });

  const [serviceCurrentPage, setServiceCurrentPage] = useState(1);
  const [servicePageSize, setServicePageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleServicePageChange = (page: number, pageSize?: number) => {
    setServiceCurrentPage(page);
    if (pageSize) setServicePageSize(pageSize);
  };

  /**
   * Handles the viewing of services associated with a specific case plan.
   * @param casePlanId - The unique identifier of the case plan.
   */
  const handleView = (casePlanId: string) => {
    const filtered = serviceRecords.filter((record) => record.case_plan_id === casePlanId);
    setFilteredServices(filtered);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const renderSkeletonOrError = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Skeleton active />
        </div>
      );
    }

    if (error) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Alert
            message="An error occurred while fetching data. Please refresh the page."
            description={error}
            type="error"
            showIcon
          />
        </div>
      );
    }

    return null;
  };

  const columns = [
    {
      title: 'Case Plan Date',
      dataIndex: 'case_plan_date',
      key: 'case_plan_date',
    },
    {
      title: 'Status',
      dataIndex: 'case_plan_status',
      key: 'case_plan_status',
      render: (text: string | null) => {
        if (!text || typeof text !== 'string') {
          return 'Not Applicable';
        }

        const statusKey = text.toLowerCase() as Status;
        const color = statusColorMap[statusKey] || 'default';

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: t('Actions'),
      dataIndex: '',
      key: 'actions',
      render: (_: any, record: CasePlan) => (
        <BaseSpace>
          <BaseButton type="primary" onClick={() => handleView(record.case_plan_id)}>
            {t('View Services')}
          </BaseButton>
        </BaseSpace>
      ),
    },
  ];

  const servicesColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string | null) => {
        if (!text || typeof text !== 'string') {
          return 'Not Applicable';
        }

        const statusKey = text.toLowerCase() as Status;
        const color = statusColorMap[statusKey] || 'default';

        return <Tag color={color}>{text}</Tag>;
      },
    }, {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
    },
    {
      title: 'Vulnerability',
      dataIndex: 'vulnerability',
      key: 'vulnerability',
    },
    {
      title: 'Goal',
      dataIndex: 'goal',
      key: 'goal',
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
    },
    {
      title: 'Service Referred',
      dataIndex: 'service_referred',
      key: 'service_referred',
    },
    {
      title: 'Institution',
      dataIndex: 'institution',
      key: 'institution',
    },

  ];

  useEffect(() => {
    if (householdId) {
      setLoading(true);
      // Fetch Case Plans
      axios
        .get(`https://server.achieve-dqa.bluecodeltd.com/household/household-case-plans/${householdId}`)
        .then((response) => {
          const data = cleanData(response.data.data) as CasePlan[];
          setCasePlans(data);
          setFilteredCasePlans(data);
          setTableData({ data: data, pagination: initialPagination, loading: false });
        })
        .catch((err) => {
          setError(err.message || 'Failed to fetch household case plans.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("[useEffect - fetchCasePlans] householdId is undefined or null");
      setError('Invalid VCA ID.');
    }
  }, [householdId]);

  useEffect(() => {
    if (householdId) {
      setLoading(true);
      // Fetch Services
      axios
        .get(`https://server.achieve-dqa.bluecodeltd.com/household/household-services/${householdId}`)
        .then((response) => {
          const data = cleanData(response.data.data) as Service[];
          setServiceRecords(data);
        })
        .catch((err) => {
          setError(err.message || 'Failed to fetch household services.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [householdId]);

  return (
    <div style={{ margin: '20px' }}>
      {renderSkeletonOrError()}

      {!isLoading && !error && (
        <>
          <BaseTable<CasePlan>
            scroll={{ x: 200 }}
            dataSource={filteredCasePlans.length ? filteredCasePlans : tableData.data}
            columns={columns}
            loading={tableData.loading}
            rowKey={(record: CasePlan) => record.case_plan_id}
          />

          <Modal
            title="Service Records"
            open={isModalVisible}
            onCancel={handleCancel}
            width="80%"
            footer={[
              <Button key="close" onClick={handleCancel}>
                Close
              </Button>,
            ]}
          >
            <Wrapper>
              <Title level={4}>Household Services</Title>
              <Table
                scroll={{ x: 200 }}
                columns={servicesColumns}
                dataSource={filteredServices}
                rowKey={(record) => record.household_id}
              />
            </Wrapper>
          </Modal>
        </>
      )}
    </div>
  );
};
